import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Notifications from "vue-notification";
import vmodal from "vue-js-modal";
const appConfig = require("./apis/config.json");
import * as Sentry from "@sentry/vue";

import "@/style/app.css";
import "@/style/datepicker.scss";
import { setRetrieveTenantInfoFunction, store } from "@/store";
import "vue-advanced-cropper/dist/style.css";

// Import the Auth0 configuration
import config from "./auth/config.json";
// Import the plugin here
import { Auth0Plugin } from "./auth";

import { getTenantInfo } from "./apis/app";
import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.prototype.$appName = "Planeolo";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: config.app.domain,
  clientId: config.app.clientId,
  audience: config.app.audience,
  onRedirectCallback: (appState) => {
    console.log("onredirect", appState, " or", window.location.pathname);
    // This is done to hide the 'code...' from Auth0. It also creates 2 '<crud_api>/users/auth' calls
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.use(Notifications, { ignoreDuplicates: true });
Vue.use(vmodal);
Vue.mixin({
  methods: {
    async getTenantInfo() {
      return await __getTenantInfo();
    },

    /** Notifications */

    notifySuccess(message, title, duration) {
      this.$notify({ title: title, text: message, type: "success", duration });
    },
    notifyError(err, title, duration) {
      this.$notify({
        title: title ?? "Error",
        text: err?.response?.data ?? err.message ?? err,
        type: "error",
        duration,
      });
    },
    notifyWarn(message, title, duration) {
      this.$notify({ title: title, text: message, type: "warn", duration });
    },

    /** Subscription related methods */

    /**
     * Checks if the subscription is active, i.e., not expired or at least within the grace period
     * @returns boolean
     */
    isSubscriptionActive() {
      return this.$auth.tenant.subscription.grace_period > new Date();
    },
    /**
     * Checks if the subscription is expired but within the grace period
     * @returns boolean
     */
    isSubscriptionWithinGracePeriod() {
      const now = new Date();
      const sub = this.$auth.tenant.subscription;
      return sub.expires < now && sub.grace_period > now;
    },
    /**
     * Checks if the number of seats being used is more than allowed in the subscription
     * @returns {boolean}
     */
    isSubscriptionExceedingSeats() {
      return (
        this.$auth.tenant.subscription.active_users >
        this.$auth.tenant.subscription.users_allowed
      );
    },
    /**
     * Checks if the subscription is beyond the grace period but not yet suspended.
     * The user here can use the system as Read Only and should be warned constantly to pay (or downgrade to the Free version).
     * @returns boolean
     */
    isSubscriptionReadOnly() {
      return !this.isSubscriptionActive() && !this.isSubscriptionSuspended();
    },
    /**
     * Checks if the subscription is beyond the grace period and beyond the 15 days of read-only period.
     * The user at this point needs to either downgrade to the Free version or Pay.
     * @returns boolean
     */
    isSubscriptionSuspended() {
      return false;
      // if (!this.$auth.tenant) return true;
      // return this.$auth.tenant.subscription.cancellation_date < new Date();
    },
  },
});

async function __getTenantInfo() {
  const defaultSubdomain =
    appConfig.environment === "prod" ? "app" : "appstaging";
  const subdomain =
    window.location.hostname === "localhost"
      ? defaultSubdomain
      : window.location.host;
  const tenantInfo = await getTenantInfo(subdomain);
  Vue.prototype.$tenantInfo = tenantInfo;

  Vue.prototype.$appName = tenantInfo.name;
  document.title = tenantInfo.name;
  return tenantInfo;
}

if (appConfig.environment === "prod" || appConfig.environment === "stage") {
  Sentry.init({
    Vue,
    environment: appConfig.environment,
    dsn: "https://180818e0cdea4951bc7e83b29e1c8b99@o4505309148807168.ingest.sentry.io/4505309150445568",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/.*\.planeolo\.com/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    enableTracing: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

setRetrieveTenantInfoFunction(__getTenantInfo);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
